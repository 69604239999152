$gray: #979797;
$yellow: #ffcf00;
$new-yellow: #fee602;
$deep-yellow: #fdc402;
$new-darkBlue: #0069b5;
$new-lightBlue: #00a9ea;
$blue: #0077d6;
$red: #ce0e2d;
$errorRed: #ad0000;
$rose: #f48793;
$violet: #b6b8dd;
$black: #110f10;
$orange: #fe5e00;
$neonYellow: #e0fc01;
$newBlue: #0077d6;

@import "imports/modal";
@import "imports/smallerStoreLocator";

@font-face {
  font-family: "Averta Regular";
  src: url("../fonts/Averta-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Averta Bold";
  font-weight: bold;
  src: url("../fonts/Averta-Bold.otf") format("opentype");
}

@font-face {
  font-family: "SE";
  src: url("../fonts/SohneSchmal-Extrafett.otf") format("opentype");
}

@font-face {
  font-family: "Dinamit-SemiBold";
  src: url("../fonts/Dinamit_Semibold.otf") format("opentype");
}

@font-face {
  font-family: "Dinamit-Bold";
  src: url("../fonts/Dinamit_Bold.otf") format("opentype");
}

@keyframes mymove {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.font-bold {
  font-weight: bold;
}

.Dinamit.Bold {
  font-family: "Dinamit-Bold";
  margin-right: 12px;
}

.dinamit-font,
.dinamit-font p {
  font-family: "Dinamit-SemiBold";
  font-size: 18px;
  letter-spacing: 0.09px;
}

.mr-10 {
  margin-right: 10px;
}

.yellow {
  color: #ffcf00;
}
.blue {
  color: #0077d6;
}
.red {
  color: #ce0e2d;
}
.rose {
  color: #f48793;
}
.violet {
  color: #b6b8dd;
}

body {
  margin: 0;
  padding: 0;
  -moz-osx-font-smoothing: grayscale; /* Firefox */
  -webkit-font-smoothing: antialiased; /* WebKit  */
  font-family: "Averta Regular";
  // letter-spacing: 2px;
  background: $blue;
}

#root {
  overflow: hidden;
}

.mt-10 {
  margin-top: 10px !important;
}
.mt-20 {
  margin-top: 20px !important;
}
.mt-30 {
  margin-top: 30px !important;
}
.mt-40px {
  margin-top: 40px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}
.mb-20 {
  margin-bottom: 20px !important;
}
.mb-30 {
  margin-bottom: 30px !important;
}
.mb-40 {
  margin-bottom: 40px !important;
}

.mr-5 {
  margin-right: 10px !important;
}
.mr-10 {
  margin-right: 20px !important;
}
.mr-15 {
  margin-right: 30px !important;
}
.mr-20 {
  margin-right: 40px !important;
}

.ml-5 {
  margin-left: 10px !important;
}
.ml-10 {
  margin-left: 20px !important;
}
.ml-15 {
  margin-left: 30px !important;
}
.ml-20 {
  margin-left: 40px !important;
}

.mt-15 {
  margin-top: 15px !important;
}
.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  &.no-max-width {
    max-width: 100%;
  }
  &.footer-wrapper {
    max-width: 600px;
    margin: 0;
  }
}

.yellow-color {
  color: $neonYellow;
}

.top-border {
  border-top: 3px solid $neonYellow;
}

.uppercase {
  text-transform: uppercase;
}
.center-align {
  text-align: center !important;
}
.right-align {
  text-align: right !important;
}
input,
textarea,
select {
  box-shadow: none !important;
  border-radius: 0 !important;
}
input {
  -webkit-box-shadow: none;
  box-shadow: none;
}
img {
  display: block;
}
h1 {
  margin: 0;
  white-space: pre-wrap;
  font-family: "SE";
  font-size: 88px;
  letter-spacing: -0.44px;
  -moz-osx-font-smoothing: grayscale; /* Firefox */
  -webkit-font-smoothing: antialiased; /* WebKit  */
}
h2 {
  margin: 0;
  font-family: "SE";
  font-size: 27px;
  letter-spacing: 0.14px;
  text-transform: uppercase;
  -moz-osx-font-smoothing: grayscale; /* Firefox */
  -webkit-font-smoothing: antialiased; /* WebKit  */
  // line-height: 1.2;
}
h3 {
  margin: 0;
  font-family: "Averta Bold";
  font-size: 20px;
  line-height: 1.2;
}
h6 {
  margin: 0;
  font-family: "Averta Bold";
  font-size: 12px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 20px;
}
p {
  margin: 0;
  font-size: 16px;
}
img {
  display: block;
}

a {
  text-decoration: none;
  color: inherit;
}

.medium {
  font-size: 14px;
}

.text-center {
  text-align: center;
}

.relative {
  position: relative;
}
.absolute {
  position: absolute;
}

.flex-row {
  display: flex;
  flex-wrap: wrap;
}

.width-100 {
  width: 100%;
}

.button-width-style {
  width: 100%;
}

@media only screen and (min-width: 768px) {
  h1 {
    font-size: 48px;
  }
  h2 {
    font-size: 32px;
  }
  h6 {
    font-size: 18px;
  }
  p {
    font-size: 18px;
  }
}
.button {
  display: inline-block !important;
  -webkit-appearance: none !important;
  font-size: 16px !important;
  padding: 10px 15px !important;
  font-weight: 900 !important;
  color: black !important;
  background: $new-yellow !important;
  border: none !important;
  outline: none !important;
  letter-spacing: 1px;
  text-decoration: none;
  box-sizing: border-box;
  -webkit-transition: all 100ms ease-in-out;
  transition: all 100ms ease-in-out;
  white-space: nowrap !important;
  border-right: 2px solid transparent !important;
  border-bottom: 2px solid transparent !important;
  border-radius: 6px !important;
  text-transform: unset !important;
  cursor: pointer;
  font-family: "Averta Bold" !important;
  &:hover {
    color: white !important;
    border-right: 2px solid darken($new-darkBlue, 10%) !important;
    border-bottom: 2px solid darken($new-darkBlue, 10%) !important;
    background: darken($new-lightBlue, 10%) !important;
    cursor: pointer;
  }
  &.outlined {
    color: white !important;
    border: 2px solid white !important;
    background: none !important;
    &:hover {
      background: $new-yellow !important;
      color: black !important;
      border: 2px solid $new-yellow !important;
      cursor: pointer;
    }
  }
}

#age-gate {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100000;
  display: flex;
  box-sizing: border-box;
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  .age-gate-content {
    display: flex;
    flex-direction: column;
    padding: 50px;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    max-width: 600px;
    z-index: 3;
    max-height: 600px;
    align-self: center;
    text-align: center;
    .spanish-toggle {
      position: absolute;
      top: 0;
      right: 0;
      span {
        color: white;
      }
    }
    .logo {
      width: 100%;
      height: auto;
      max-width: 150px;
      margin: 0 auto;
      img {
        width: 100%;
      }
    }
    h2 {
      color: inherit;
      margin: 40px auto;
    }
    a {
      font-weight: bold;
    }
    p {
      margin: 20px 0px;
      color: inherit;
    }
    form {
      margin-bottom: 20px;
      line-height: 1;
      text-align: center !important;

      .error-message {
        display: block;
        position: absolute;
        padding: 20px;
        top: 0;
        left: 0;
        width: 100%;
        background: red;
        color: white;
        box-sizing: border-box;
      }
    }
    .legal-text {
      p {
        font-size: 15px;
      }
      a {
        font-weight: 900;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  #age-gate {
    .age-gate-content {
      padding: 50px;
    }
  }
}

header {
  padding: 25px;
  // background: rgba(0, 0, 0, 0.25);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  box-sizing: border-box;
  .wrapper {
    // text-align: center;
    display: flex;
    .logo-container {
      width: auto;
      height: 20px;
      display: inline-block;
      margin-right: auto;
      .logo {
        width: auto;
        height: 20px;
      }
    }
    .nav-menu {
      width: 24px;
      height: 20px;
      display: block;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      cursor: pointer;
      .open-mobile-nav {
        width: 40px;
        height: 40px;
      }
    }
    #navigation-container {
      position: relative;
      .open-mobile-nav {
        position: absolute;
        top: 28px;
        left: 17px;
        img {
          width: 40px;
          height: 40px;
        }
      }
      &.open {
        transform: translateX(0);
        background: $newBlue;
        a {
          color: $neonYellow;
        }
      }
      transition: transform 200ms ease-out;
      transform: translateX(-100%);
      text-align: left;
      position: fixed;
      top: 0;
      left: 0;
      background: $newBlue;
      height: 100vh;
      padding: 60px 0px;
      min-width: 60%;
      padding-top: 100px;
      box-sizing: border-box;
      z-index: 100;
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
      a {
        display: block;
        color: $neonYellow;
        font-family: "Averta Bold";
        font-size: 22px;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.22;
        letter-spacing: normal;
        cursor: pointer;
        padding: 20px;
        border-top: 1px solid #02b5d5;
        &:hover {
          text-decoration: underline;
          color: #02b5d5;
        }
        &.active {
          color: $neonYellow;
          text-decoration: underline;
        }
        &:first-of-type {
          padding: 0px 20px;
          border: 0;
        }
        &:last-of-type {
          border-bottom: 1px solid #02b5d5;
          margin-right: 0;
        }
        .dropdown {
          display: none;
          &.dd-active {
            display: block;
          }
          a {
            // border: none;
            padding-left: 0;
            &:first-of-type {
              padding: 20px 0px;
              margin-top: 10px;
              // border-top: 1px solid #eaeaea;
              // border-top: 1px solid $gray;
            }
            &:last-of-type {
              border-bottom: 0;
              padding-bottom: 0;
            }
          }
        }
        img {
          width: 50%;
          margin-bottom: 30px;
        }
      }
      .nav-close {
        width: 20px;
        height: 20px;
        background: black;
        display: block;
        position: absolute;
        left: 20px;
        top: 20px;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  header {
    // overflow: auto;
    .wrapper {
      .nav-menu {
        display: none;
      }
      .logo-container {
        display: block;
        height: 30px;
        float: left;
        .logo {
          height: 30px;
          width: auto;
        }
      }
      #navigation-container {
        transform: translateX(0) !important;
        visibility: visible !important;
        transition: none !important;
        box-shadow: none;
        position: static;
        background: none;
        width: auto;
        height: auto;
        padding: 0;
        transition: none;
        -webkit-transition: none;
        min-width: auto;
        a {
          margin-bottom: 0;
          display: inline-block;
          margin-right: 20px;
          font-size: 16px;
          font-weight: 900;
          -webkit-transition: all 100ms ease-in-out;
          transition: all 100ms ease-in-out;
          color: $neonYellow;
          border: none !important;
          padding: 0;
          line-height: 30px;
          &.active {
            color: $neonYellow;
            text-decoration: underline;
          }
          .dropdown {
            display: none;
            position: absolute;
            top: 40px;
            right: 0;
            // width: 150px;
            text-align: right;
            background: rgba(0, 0, 0, 0.25);
            z-index: 20;
            padding: 10px;
            &.dd-active {
              display: block;
            }
            a {
              display: block;
              padding: 0px 20px !important;
              text-align: right;

              &:first-of-type {
                padding-top: 0;
                margin: 0 !important;
                border-top: none;
              }
            }
          }
        }
        float: right;
      }
    }
  }
}
.extra-margin {
  height: 70px;
}

@media only screen and (min-width: 600px) {
  .extra-margin {
    height: 80px;
  }
}
.hero-section {
  position: relative;
  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-size: contain !important;
    background-position: center center !important;
    background-repeat: no-repeat !important;
    transform: scale(0.8);
    transition: transform 500ms ease-in-out;
  }
  .background-color {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .wrapper {
    min-height: 70vh;
    .text-content {
      z-index: 3;
      position: relative;
      padding: 50px 25px;
      min-height: 70vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      box-sizing: border-box;

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: white;
      }
      p {
        color: white;
        img {
          max-width: 80%;
          margin: 0 auto;
        }
      }
      .buttons {
        .button {
          margin: 10px;
        }
      }
    }
  }
}

@media only screen and (max-width: 599px) {
  .hero-section {
    .wrapper {
      .text-content {
        justify-content: flex-end;
        text-align: center !important;
        margin-top: 50px;
        .right-align {
          text-align: center !important;
        }
      }
    }
  }
}

@media only screen and (min-width: 600px) {
  .hero-section {
    &.left-aligned {
      .wrapper {
        justify-content: flex-start;
      }
    }
    &.right-aligned {
      .wrapper {
        justify-content: flex-end;
      }
    }
    .overlay {
      display: none;
    }
    .wrapper {
      display: flex;
      .text-content {
        padding: 170px 50px;
        width: auto;
        width: 50%;
        box-sizing: border-box;
        min-height: auto;
        .buttons {
          margin-left: 0;
          margin-top: 20px;
        }
      }
    }
    &.vertical {
      .wrapper {
        flex-direction: column;
        .text-content {
          padding: 50px;
          width: 100%;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .hide-desktop {
    display: none !important;
  }
  .section-half {
    padding: 100px 50px;
    .wrapper {
      flex-direction: row;
      .text-content,
      .img-content {
        width: 50%;
      }
      .text-content {
        order: 2;
        margin-bottom: 0;
        margin-left: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-right: 40px;
        box-sizing: border-box;
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#landing-page {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center center;
  position: relative;
  animation-name: fadeIn;
  animation-duration: 1s;
  animation-delay: 1s;

  #logo {
    position: absolute;
    bottom: 10px;
    left: 10px;
    width: 40%;
    height: auto;
    img {
      width: 100%;
      height: auto;
      display: block;
    }
  }
}

@media only screen and (min-width: 768px) {
  #landing-page {
    #logo {
      width: 20%;
    }
  }
}

.products {
  background: black;
  padding: 50px 25px;
  text-align: center;
  .section-description {
    color: white;
    text-align: center;
    margin-bottom: 20px;
  }
  .button {
    margin-top: 20px;
  }

  .product-icons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    box-sizing: border-box;
    .product-icon {
      text-align: center;
      width: 30%;
      img {
        width: 100px;
        margin: 0 auto;
        margin-bottom: 20px;
      }
      h3 {
        color: white;
        text-transform: uppercase;
        font-size: 12px;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .products {
    padding: 100px 50px;
    .product-icons {
      .product-icon {
        width: auto;
        img {
          width: 150px;
        }
      }
    }
  }
}

.logo-text {
  font-weight: 900;
  font-size: 16px;
  letter-spacing: 8px;
  white-space: nowrap;
}

footer {
  background: #0077d6;
  background-size: cover;
  background-position: center center;
  color: $neonYellow;
  .wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .footer-section {
      margin-bottom: 30px;
      width: 50%;
      h2 {
        font-size: 16px;
        font-family: "Averta Bold";
      }
      p {
        font-size: 14px;
        margin-bottom: 10px;
        &:last-of-type {
          margin-bottom: 0;
        }
        &.copyright {
          margin-bottom: 20px;
          font-weight: 900;
        }
      }
      .social {
        margin-top: 20px;
        a {
          display: inline-block;
          margin-right: 10px;
          width: 20px;
          height: auto;
          margin-bottom: 0;
          color: $neonYellow;

          img {
            width: 100%;
            height: auto;
          }
        }
      }
      a {
        display: block;
        color: $neonYellow;
        margin-bottom: 10px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
      &:first-of-type,
      &:last-of-type {
        width: 100%;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

@media only screen and (min-width: 900px) {
  footer {
    padding: 60px 0 60px 60px !important;
    .wrapper {
      flex-wrap: wrap;
      justify-content: space-between;
      .footer-section {
        margin-right: 20px;
        width: auto;
        order: 1;
        .social {
          margin: 0;
        }
        .copyright {
          margin-top: 0;
        }
        &:first-of-type {
          // max-width: 300px;
          // order: 2;
        }
        &:last-of-type {
          flex-basis: 100%;
          order: 3;
        }
      }
    }
  }
}

//INSTAGRAM

.instagram-section {
  padding: 56px 24px;
  .photos {
    margin-top: 25px;
    img {
      display: none;
      width: 100%;
      &:first-of-type {
        display: block;
      }
    }
  }
}

@media only screen and (min-width: 600px) {
  .instagram-section {
    padding: 100px 50px;
    h2 {
      float: left;
    }
    .button {
      float: right;
      margin-bottom: 25px;
    }
    .photos {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      clear: both;
      img {
        display: block;
        width: calc(100% / 3 - 20px);
        margin-right: 30px;
        // flex-basis: 0;
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
}

//PRODUCTS PAGE

.products-page-section {
  min-height: 400px;
  .wrapper {
    .carousel {
      padding: 25px;
      .options {
        span {
          display: inline-block;
          padding: 10px;
          font-size: 12px;
          font-weight: 900;
          text-transform: uppercase;
          border-radius: 16px;
          box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.12);
          margin-right: 10px;
          margin-bottom: 10px;
          border: 2px solid transparent;
          cursor: pointer;
          &:last-of-type {
            margin-right: 0;
          }
          &.active {
            // box-shadow: none;
            background: black;
            color: white;
          }
        }
      }
      .form-info-container {
        .selected-form {
          width: 60%;
          height: auto;
          margin: 25px auto;
        }
        .text-content {
          p:last-of-type {
            margin-top: 15px;
          }
        }
      }
    }
    .product-carousel {
      position: relative;
      .images {
        width: 100%;
        height: 50vh;
        .product-image {
          width: 100%;
          height: 100%;
          display: none;
          object-fit: cover !important;
          object-position: center center !important;
          &.active {
            display: block;
          }
        }
      }
      .controls {
        position: absolute;
        bottom: 25px;
        right: 25px;
        z-index: 2;
        div {
          background: white;
          border-radius: 100%;
          float: left;
          width: 50px;
          height: 50px;
          text-align: center;
          line-height: 50px;
        }
        .forward {
          &:before {
            content: ">";
          }
        }
        .back {
          margin-right: 20px;
          &:before {
            content: "<";
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 600px) {
  .products-page-section {
    .wrapper {
      .carousel {
        padding: 25px 50px;
        .options {
          text-align: center;
        }
        .form-info-container {
          display: flex;
          flex-direction: row;
          .selected-form {
            margin-right: 25px;
          }
          .text-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 25px 0px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 900px) {
  .products-page-section {
    padding: 50px;
    .wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      .product-carousel {
        min-width: 60%;
        margin: 0;
        .images {
          height: 100%;
        }
      }
      .carousel {
        padding: 0;
        padding-right: 25px;
        min-width: 25%;
        .options {
          text-align: left;
        }
        .form-info-container {
          display: block;
          .selected-form {
            margin: 25px auto;
            width: 100%;
          }
          .text-content {
            padding: 0;
          }
        }
      }
    }
  }
}

.simple-section {
  padding: 50px 25px;
}

@media only screen and (min-width: 600px) {
  .simple-section {
    padding: 100px 50px;
    .wrapper {
      display: flex;
      flex-direction: row;
      h2 {
        margin-right: 10%;
      }
    }
  }
}

//PRISMIC PAGES

.simple-page {
  padding: 25px;
  p,
  li,
  a {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    color: black;
    letter-spacing: normal;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  &.full-screen-page {
    padding: 0 !important;
    margin-top: 74px;
  }
}

@media only screen and (min-width: 600px) {
  .simple-page {
    padding: 50px;
    &.full-screen-page {
      padding: 0 !important;
      margin-top: 74px;
    }
  }
}

@media only screen and (min-width: 768px) {
  .simple-page {
    &.full-screen-page {
      margin-top: 86px;
    }
  }
}

.map-wrapper {
  overflow: auto;
  background-size: cover;
  .interactive-map {
    width: 100%;
    display: block;
    position: relative !important;
    display: none;
  }
  .locations-list-wrapper {
    display: block;
    padding-top: 74px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    &.custom-map {
      padding-top: 0;
      .locations-list-title {
        margin-top: 0;
      }
    }
    .locations-list-title {
      margin: 0;
      padding: 0px 20px;
      color: white;
      margin-top: 20px;
    }
    .pagination-nav {
      padding: 8px 20px;
      display: flex;
      justify-content: space-between;
      background: $neonYellow;
      color: black;
      .page-controls {
        span {
          cursor: pointer;
          &:nth-of-type(1) {
            margin-right: 10px;
          }
        }
      }
    }
    .info-message {
      background: $new-darkBlue;
      color: white;
      padding: 8px 20px;
    }
    .locations-search-form {
      padding: 10px 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
    .locations-list {
      background: white;
      overflow: scroll;
      height: 100%;
      position: relative;
      .single-location {
        padding: 20px;
        overflow: auto;
        border-bottom: 1px solid #000000;
        display: flex;
        flex-direction: row;
        &:last-of-type {
          border-bottom: 0;
        }
        &.active {
          background: lighten($new-lightBlue, 50%);
        }

        .store-information {
          margin-right: 10px;
          padding-right: 10px;
          border-right: 1px solid #000000;
          width: 100%;
          color: #000000;
          .store-name {
            font-size: 14px;
            line-height: 1.2;
            margin-bottom: 5px;
            color: $new-darkBlue;
            font-family: "Dinamit-SemiBold";
          }
          .store-address {
            font-size: 14px;
            line-height: 1.2;
            margin-bottom: 10px;
          }
          .distance {
            font-size: 14px;
            line-height: 1.2;
          }
          .usage-type {
            font-size: 12px;
            font-weight: 900;
            margin-bottom: 0;
          }
        }
        .store-actions {
          min-width: 110px;
          .icon-button {
            color: #000000;
            font-size: 10px;
            display: block;
            text-decoration: none;
            font-weight: 900;
            margin-bottom: 10px;
            white-space: nowrap;
            svg {
              display: inline-block;
              vertical-align: middle;
              margin-right: 5px;
              fill: #000000;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .map-wrapper {
    margin-top: 80px;
    box-shadow: inset -4px 8px 10px -10px rgba(11, 93, 156, 1);

    overflow: auto;
    .interactive-map {
      height: 100vh !important;
      width: 60% !important;
      float: right;
      display: block;
    }
    .stores-wrapper {
      height: calc(100vh - 73px) !important;
      width: 40%;
      float: left;
    }
  }
}

.gm-style {
  font: inherit !important;
  .gm-style-iw-c {
    border-radius: 0 !important;
  }
}

.info-window-content {
  .store-name {
    font-size: 16px;
    line-height: 1.2;
    margin-bottom: 5px;
    color: #000000;
  }
  .store-address {
    font-size: 14px;
    line-height: 1.2;
    margin-bottom: 10px;
  }
  .usage-type {
    font-size: 16px;
    margin-bottom: 10px;
  }
  a {
    text-decoration: none;
  }
}

#alert-dialog-description {
  p {
    a {
      color: black;
      text-decoration: underline;
    }
  }
}

.no-flick {
  min-height: 80vh;
}

.animation {
  opacity: 0;
  &.yas {
    -webkit-transition: opacity 300ms ease-in-out;
    transition: opacity 300ms ease-in-out;
    opacity: 1;
    .hero-section {
      .background-image {
        transform: scale(1);
      }
    }
  }
}

.map-info-window {
  .button {
    margin-top: 10px;
  }
}

.featured-section {
  .wrapper {
    .featured-item {
      margin: 0 auto;
      margin-bottom: 16px;
      &:last-of-type {
        margin-bottom: 0;
      }
      .image {
        border-radius: 10px;
        border: 3px solid white;
        overflow: hidden;

        img {
          width: 100%;
          height: auto;
          display: block;
        }
      }
      .content {
        padding: 20px;
      }
    }
  }
}

@media only screen and (min-width: 600px) {
  .featured-section {
    .wrapper {
      max-width: 900px;
      .featured-item {
        margin-bottom: 0 !important;
        max-width: calc(50% - 25px);
        .image {
          margin: 0 auto;
        }
        &:first-of-type {
          margin-right: 25px;
        }
        &:last-of-type {
          margin-left: 25px;
        }
        .content {
          padding: 20px 0px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1200px) {
  .featured-section {
    // padding: 96px;
    .wrapper {
      .featured-item {
        max-width: calc(50% - 24px);
        &:first-of-type {
          margin-right: 24px;
        }
        &:last-of-type {
          margin-left: 24px;
        }
      }
    }
  }
}

.text-section {
  background-size: cover !important;
  background-position: center center !important;
  .wrapper {
    p {
      margin: 20px 0px;
    }
  }
}

@media only screen and (min-width: 600px) {
  .text-section {
    padding: 114px 48px;
  }
}

@media only screen and (min-width: 768px) {
  .flex-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    &.lead-image {
      .image-wrapper {
        order: -1;
      }
    }
  }
}
.product-section {
  background: $blue;
  color: white;
  .wrapper {
    .button-wrapper {
      margin: 24px 0px;
    }
    .content-wrapper {
      max-width: 600px;
    }
    .products-wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      overflow: scroll;
      .product {
        min-width: 130px;
        a {
          &:hover {
            img {
              transform: scale(0.9);
            }
          }
        }
        img {
          width: 100%;
          display: block;
          max-width: 250px;
          margin: 0 auto;
          transition: all 100ms ease-in-out;
        }
        .content {
          margin-top: 20px;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .product-section {
    .wrapper {
      .content-wrapper {
        margin-right: 30px;
        margin-bottom: 50px;
      }
      .button-wrapper {
        margin: 0;
      }
      .flex-wrapper {
        align-items: flex-start;
      }
      .products-wrapper {
        justify-content: center;
        overflow: hidden;
        .product {
          margin: 0px 20px;
        }
      }
    }
  }
}

.card-swipe-section {
  &.standard-padding {
    padding-bottom: 35px;
  }
  .button-wrapper {
    margin: 24px 0px;
  }
  .products-wrapper {
    display: flex;
    overflow: scroll;
    padding-bottom: 15px;
    .card {
      min-width: 230px;
      min-height: 230px;
      margin-right: 16px;
      position: relative;
      border-radius: 10px;
      overflow: hidden;
      box-sizing: border-box;
      cursor: pointer;
      &:hover {
        .content {
          opacity: 1;
        }
      }
      .image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          display: block;
          object-fit: cover;
          object-position: center center;
          transition: all 300ms ease-in-out;
        }
      }
      .content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: white;
        padding: 20px;
        box-sizing: border-box;
        text-align: center;
        opacity: 0;
        background: rgba(0, 0, 0, 0.25);
        transition: all 300ms ease-in-out;
        h2 {
          margin-bottom: 10px;
        }
        h3 {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .card-swipe-section {
    .wrapper {
      .flex-wrapper {
        .flex-child {
          &:nth-of-type(1) {
            margin-right: 48px;
            max-width: 50%;
          }
          &:nth-of-type(2) {
            width: 100%;
            width: 50%;
          }
          .products-wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            column-gap: 24px;
            row-gap: 24px;
            padding: 0;
            &.products-carousel {
              display: block;
              .control-arrow {
                width: 50px;
                height: 50px;
                top: calc(50% - 25px);
                border-radius: 100%;
                &.control-prev {
                  left: 10px;
                }
                &.control-next {
                  right: 10px;
                }
              }
            }
            .card {
              margin: 0;
              min-width: auto;
              min-height: auto;
              .image {
                position: static;
              }
            }
          }
        }
      }
    }
  }
}

.text-image-section {
  .wrapper {
    max-width: 1300px;
    .content-wrapper {
      width: 100%;
      box-sizing: border-box;
    }
    .button-wrapper {
      margin-top: 24px;
    }

    .image-wrapper {
      max-width: 50%;
      margin-left: auto;
      margin-top: -100px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
      }
    }
    &.full-width {
      max-width: none !important;
      .image-wrapper {
        margin-left: 0;
        margin-top: 0;
        max-width: none;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .text-image-section {
    .wrapper {
      .image-wrapper {
        min-width: 50%;
        max-width: 50%;
        align-self: stretch;
        margin-left: 0;
        margin-top: 0;
        max-width: none;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .button-wrapper {
    .button {
      width: auto !important;
    }
  }
}

.forms-section {
  transition: all 500ms ease-in-out;
  overflow: hidden;
  .wrapper {
    .flex-wrapper {
      align-items: flex-start;
      .content-wrapper {
        margin-bottom: 15px;
        z-index: 1;
        .text-content {
          margin-bottom: 20px;
        }
        .pills {
          display: flex;
          overflow: scroll;
          padding-bottom: 15px;
          .button {
            margin-right: 8px;
            cursor: pointer;
            &.active {
              background: $new-yellow !important;
              color: black !important;
              border: 2px solid $new-yellow !important;
            }
          }
        }
      }
      .image-wrapper {
        display: flex;
        flex-direction: column;
        .content {
          margin-top: 30px;
        }
        .form-images {
          position: relative;
          height: 50vh;
          z-index: 0;
          order: -1;
          .form-image {
            position: absolute;
            width: 100%;
            height: 100%;
            opacity: 0;
            bottom: 0;
            left: 0;
            transform: translateX(50%);
            transition: all 300ms ease-in-out;
            box-sizing: border-box;
            &.visible {
              opacity: 1;
              transform: translateX(0);
            }
            img {
              height: 100%;
              width: 100%;
              max-width: 400px;
              object-fit: contain;
              object-position: center center;
              transition: all 500ms ease-in-out;
            }
          }
        }
      }
    }

    .form-content {
      display: none;
      &.visible {
        display: block;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .forms-section {
    .wrapper {
      .flex-wrapper {
        min-height: 70vh;
        align-items: stretch;
        flex-direction: column;
        justify-content: unset;
        .content-wrapper {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .text-content {
            padding-right: 20px;
            width: auto;
            h2 {
              margin: 0;
            }
          }
          .pills {
            display: block;
            overflow: unset;
            width: auto;
            padding-bottom: 0;
            text-align: right;
            .button {
              margin-bottom: 8px;
              &:last-of-type {
              }
            }
          }
        }
        .image-wrapper {
          min-width: 50%;
          height: auto;
          flex-direction: row;
          .content {
            width: 50%;
            margin-top: 0;
            align-self: center;
          }
          .form-images {
            width: 50%;
            order: 2;
            .form-image {
              padding: 0;
              transform: translateY(50%);
              &.visible {
                transform: translateY(0);
              }
              img {
                max-width: none;
                object-position: center right;
              }
            }
            &.lead-image {
              order: -1;
              img {
                object-position: center left;
              }
            }
          }
        }
      }
    }
  }
}

.moods-section {
  .wrapper {
    .flex-wrapper {
      .mood-cards-wrapper {
        margin-top: 24px;
        .mood-card {
          height: 100%;
          box-shadow: 1px -1px 4px 0 rgba(0, 0, 0, 0.1);
          background-color: #ffffff;
          img {
            width: 100%;
            height: auto;
          }
          .content {
            padding: 32px 24px;
          }
          display: none;
          &.visible {
            display: block;
          }
        }
      }
      .mood-images-wrapper {
        margin-top: 16px;
        img {
          width: 100%;
        }
        .mood-image {
          display: none;
          &.visible {
            display: block;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .moods-section {
    // padding: 32px;
    .wrapper {
      .flex-wrapper {
        align-items: flex-start;
        .mood-cards-wrapper,
        .mood-images-wrapper {
          margin-top: 0;
        }
        .mood-cards-wrapper {
          width: calc(50% - 32px);
          margin-right: 32px;
          align-self: stretch;
        }
      }
    }
  }
}

.standard-padding {
  padding: 50px 25px;
}

.standard-padding-less {
  padding: 25px 15px;
}

@media only screen and (min-width: 600px) {
  .standard-padding {
    padding: 50px;
  }
}

.faq-section {
  table {
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin: 0 !important;
    }
    div {
      margin: 0;
    }
    .answer {
      padding: 20px;
    }
  }
}

.website-message {
  // display: block !important;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  // background: $new-darkBlue;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: rgb(0, 40, 111);
  z-index: 1000;
  .button {
    margin-left: 20px;
  }
}

.pdf-section {
  .react-pdf__Document {
    pointer-events: none;
    -webkit-user-select: none;
    overflow: hidden;
  }
  .react-pdf__Page__canvas {
    width: 100% !important;
    height: auto !important;
  }
  .pdf-navigation {
    text-align: center;
    z-index: 20;
    position: relative;
    .page-indicator {
      margin-bottom: 10px;
    }
    .button:first-of-type {
      margin-right: 10px;
    }
    .button {
      z-index: 20;
    }
  }
}

.iframe-slice {
  width: 100%;
  background: white;
  iframe {
    width: 100%;
    height: 70vh;
    border: 0;
  }
  padding: 60px 0px;
}

#contact-page {
  .wrapper {
    max-width: 700px;
    padding: 20px;
  }
}

#contact-header {
  background: $blue;
  margin-top: 75px;
  h1 {
    text-align: center;
    padding: 40px 0px;
    color: white;
    margin: 0;
  }
}

.form-message {
  display: block;
  color: black;
  background: rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 5px;
  &.error {
    margin-bottom: 2rem;
    color: white;
    background: none;
    background: $errorRed;
    font-weight: bold;
  }
  .underline {
    text-decoration: underline;
    font-weight: bold;
  }
  &.success .brandColor {
    color: $new-yellow;
    font-weight: bold;
  }
}

.MuiFormLabel-root.Mui-error {
  background: $errorRed !important;
  color: white !important;
  display: inline-block !important;
  padding: 8px;
  border-radius: 5px;
  font-weight: bold;
}

.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: $errorRed !important;
}

.tooltipButton {
  margin: 0;
  padding: 0;
  height: 18px;
  width: 18px;
  background: none;
  border: none;
  cursor: pointer;
}

.flex {
  display: flex;
}

.accessibility {
  display: inline-block;
  margin-left: 10px;
  position: relative;
  bottom: 6px;
}

// NEW STYLES

.yellow-font {
  color: $neonYellow;
}

.navbar.scrolled {
  background: #0077d6;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}

.new-hero-copy h1 {
  @media only screen and (min-width: 600px) {
    font-size: 150px;
    line-height: 120px !important;

    .yellow-color {
      bottom: 5px !important;
    }
  }

  @media only screen and (min-width: 810px) {
    font-size: 200px;
    line-height: 155px !important;

    .yellow-color {
      bottom: 10px !important;
    }
  }

  @media only screen and (min-width: 1120px) {
    font-size: 250px;
    line-height: 191px !important;

    .yellow-color {
      bottom: 10px !important;
    }
  }
  @media only screen and (min-width: 1310px) {
    .yellow-color {
      bottom: 5px !important;
    }
  }
  @media only screen and (min-width: 1386px) {
    font-size: 280px;
    line-height: 220px !important;
    .yellow-color {
      bottom: 20px !important;
    }
  }

  @media only screen and (min-width: 1850px) {
    font-size: 300px;
    line-height: 240px !important;
    .yellow-color {
      bottom: 25px !important;
    }
  }
}

@media only screen and (min-width: 1400px) {
  .standard-padding-less {
    padding: 25px 70px;
  }

  footer {
    padding: 109px 0 109px 131px !important;
  }

  header {
    .wrapper {
      justify-content: flex-start;
      align-items: flex-start;

      .logo-container {
        margin-right: 670px;
        .logo {
          height: 40px;
        }
      }
      #navigation-container {
        a {
          margin-right: 60px !important;
          font-size: 20px;
        }
      }
    }
  }
}

.deep-yellow-background {
  background: $deep-yellow;
}

.orange-background {
  color: $deep-yellow;
  background: $orange;
}

.inheritHW {
  height: inherit;
  width: inherit;
}

.orange-font {
  color: $orange;
  h2 {
    line-height: 0.9;
  }
  p {
    font-size: 14px;
    font-weight: bold;
  }
  @media only screen and (min-width: 850px) {
    p {
      font-size: 16px;
    }
  }
}

.big-game-btns {
  margin-top: 20px;
  button {
    background: $orange !important;
    color: $deep-yellow !important;
    font-family: "SE";
    font-size: 18px;
    padding: 5px 25px;
    white-space: nowrap;
    min-width: 155px;
    margin-bottom: 10px;
    margin-right: 20px;
    @media only screen and (min-width: 1300px) {
      font-size: 28px;
      text-align: center;
      min-width: 200px;
    }
    @media only screen and (min-width: 1500px) {
      font-size: 35px;
      min-width: 230px;
    }
  }
  @media only screen and (min-width: 1300px) {
    margin-top: 50px;
    text-align: center;
  }
}

.big-game-locations {
  padding: 40px 20px;
  text-align: center;

  .copy {
    h2 {
      margin-bottom: 10px;
    }
    p {
      max-width: 850px;
      margin: auto;
      line-height: 1.3;
    }
  }

  .locations {
    margin-top: 30px;
    h3 {
      font-family: "SE";
      font-size: 25px;
    }
    .location-info {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 20px;
      gap: 3px;
      p {
        font-size: 14px;
        @media only screen and (min-width: 400px) {
          font-size: 15px;
        }
      }
      .state p:after {
        content: ",";
      }
    }
  }
}

@media only screen and (min-width: 850px) {
  .big-game-locations {
    padding: 40px 20px 50px;

    .copy {
      h2 {
        font-size: 42px;
        margin-bottom: 10px;
      }
    }

    .locations {
      margin-top: 30px;
      h3 {
        font-size: 35px;
      }
      .location {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        &:hover {
          text-decoration: underline;
        }

        .location-name {
          margin-right: 5px;
        }
      }

      .location-info {
        p {
          font-size: 18px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1300px) {
  .big-game-locations {
    padding: 60px 20px 90px;

    .copy {
      h2 {
        font-size: 90px;
        margin-bottom: 10px;
        text-align: center;
      }
      p {
        font-size: 24px;
        text-align: center;
        line-height: 1.3;
        max-width: 825px;
        margin: auto;
      }
    }

    .locations {
      margin-top: 50px;

      p,
      .location-info p {
        font-size: 18px;
      }
    }
  }
}

@media only screen and (min-width: 1540px) {
  .locations-container {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    gap: 70px;
    @media only screen and (min-width: 1630px) {
      gap: 100px;
    }
    @media only screen and (min-width: 1700px) {
      gap: 150px;
    }
  }
}
