.shop-page-dialog .faq-popover .MuiPopover-paper {
  bottom: 20px;
  right: 20px;
}

.shop-page-dialog .MuiPaper-rounded {
  border-radius: 0px !important;
}

.shop-page-dialog .MuiDialog-paperWidthSm {
  max-width: 850px !important;
}

.shop-page-dialog .MuiBackdrop-root {
  background: #292929 !important;
}

.dialog-wrapper {
  position: relative;
  text-align: center;
  padding: 20px;
  text-align: center;
  background: #0077d6;
  color: white;
}

.dialog-wrapper .close {
  position: absolute;
  top: 0px;
  right: 15px;
}

.dialog-wrapper .button-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-top: 30px;
}

.dialog-wrapper .button-container .sunny-logo,
.dialog-wrapper .button-container .jane-logo {
  margin: 0 auto 25px;
  display: block;
  border-radius: 10px;
}

.dialog-wrapper .button-container .sunny-logo {
  width: 150px;
}

.dialog-wrapper .button-container .jane-logo {
  width: 86px;
}

.dialog-wrapper .button-container .shop-sunny {
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  padding-bottom: 35px;
  margin-bottom: 32px;
}

.dialog-wrapper .button-container a {
  text-decoration: none;
}

.dialog-wrapper .button-container a button {
  font-size: 12px;
  background: none;
  color: black;
  background: #e0fc01;
  -webkit-transition: 200ms ease;
  transition: 200ms ease;
}

.dialog-wrapper .logo-container {
  padding: 20px 20px 10px 20px;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.dialog-wrapper .logo-container svg,
.dialog-wrapper .logo-container img {
  max-width: 140px;
  margin: auto;
}

.dialog-wrapper .logo-container svg path,
.dialog-wrapper .logo-container img path {
  fill: white;
}

.dialog-wrapper .content {
  margin: 10px auto 10px;
  max-width: 450px;
}

.dialog-wrapper .content p {
  font-size: 16px;
  color: white;
}

.dialog-wrapper .disclaimer-text p {
  font-size: 12px;
  line-height: 16px;
  max-width: 500px;
  margin: 35px auto 0 auto;
  color: white;
}

.dialog-wrapper .disclaimer-text p a {
  color: white;
  text-decoration: underline;
}

@media only screen and (min-width: 900px) {
  .dialog-wrapper .button-container {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    margin-top: 30px;
  }
  .dialog-wrapper .button-container .sunny-logo,
  .dialog-wrapper .button-container .jane-logo {
    display: block;
  }
  .dialog-wrapper .button-container .sunny-logo {
    width: 198px;
    margin-bottom: 35px;
  }
  .dialog-wrapper .button-container .jane-logo {
    width: 104px;
  }
  .dialog-wrapper .button-container .shop-sunny {
    border-right: 1px solid rgba(255, 255, 255, 0.4);
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
    padding: 15px 35px 0 35px;
  }
  .dialog-wrapper .button-container .shop-jane {
    padding: 15px 35px 0 35px;
  }
  .dialog-wrapper .button-container a button {
    font-size: 16px;
  }
  .dialog-wrapper .logo-container {
    margin-bottom: 20px;
  }
  .dialog-wrapper .logo-container svg {
    max-width: 180px;
  }
  .dialog-wrapper .content {
    margin-bottom: 50px;
  }
  .dialog-wrapper .content p {
    font-size: 20px;
  }
  .dialog-wrapper .disclaimer-text {
    margin-top: 50px;
  }
}

.custom-map .map-wrapper {
  overflow: auto;
  background-size: cover;
}

.custom-map .map-wrapper .interactive-map {
  width: 100%;
  display: block;
  position: relative !important;
  display: none;
}

.custom-map .map-wrapper .locations-list-wrapper {
  display: block;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding-top: 0;
}

.custom-map .map-wrapper .locations-list-wrapper .locations-list-title {
  margin: 0;
  padding: 20px 20px 0;
  color: white;
  margin-top: 20px;
}

.custom-map .map-wrapper .locations-list-wrapper .pagination-nav {
  padding: 8px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background: #fee602;
  color: black;
}

.custom-map .map-wrapper .locations-list-wrapper .pagination-nav .page-controls span {
  cursor: pointer;
}

.custom-map .map-wrapper .locations-list-wrapper .pagination-nav .page-controls span:nth-of-type(1) {
  margin-right: 10px;
}

.custom-map .map-wrapper .locations-list-wrapper .info-message {
  background: #0069b5;
  color: white;
  padding: 8px 20px;
  border-top: 1px solid #00497e;
}

.custom-map .map-wrapper .locations-list-wrapper .locations-search-form {
  padding: 10px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.custom-map .map-wrapper .locations-list-wrapper .locations-search-form-wrapper {
  background: #0069b5;
}

.custom-map .map-wrapper .locations-list-wrapper .locations-list {
  background: white;
  overflow: scroll;
  height: 100%;
  position: relative;
}

.custom-map .map-wrapper .locations-list-wrapper .locations-list .single-location {
  padding: 20px;
  overflow: auto;
  border-bottom: 1px solid #000000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.custom-map .map-wrapper .locations-list-wrapper .locations-list .single-location:last-of-type {
  border-bottom: 0;
}

.custom-map .map-wrapper .locations-list-wrapper .locations-list .single-location.active {
  background: #eaf9ff;
}

.custom-map .map-wrapper .locations-list-wrapper .locations-list .single-location .store-information {
  margin-right: 10px;
  padding-right: 10px;
  border-right: 1px solid #000000;
  width: 100%;
  color: #000000;
}

.custom-map .map-wrapper .locations-list-wrapper .locations-list .single-location .store-information .store-name {
  font-size: 16px;
  line-height: 1.2;
  margin-bottom: 5px;
  color: #0069b5;
}

.custom-map .map-wrapper .locations-list-wrapper .locations-list .single-location .store-information .store-address {
  font-size: 14px;
  line-height: 1.2;
  margin-bottom: 10px;
}

.custom-map .map-wrapper .locations-list-wrapper .locations-list .single-location .store-information .distance {
  font-size: 14px;
  line-height: 1.2;
}

.custom-map .map-wrapper .locations-list-wrapper .locations-list .single-location .store-information .usage-type {
  font-size: 12px;
  font-weight: 900;
  margin-bottom: 0;
}

.custom-map .map-wrapper .locations-list-wrapper .locations-list .single-location .store-actions {
  min-width: 110px;
}

.custom-map .map-wrapper .locations-list-wrapper .locations-list .single-location .store-actions .icon-button {
  color: #000000;
  font-size: 10px;
  display: block;
  text-decoration: none;
  font-weight: 900;
  margin-bottom: 10px;
  white-space: nowrap;
}

.custom-map .map-wrapper .locations-list-wrapper .locations-list .single-location .store-actions .icon-button svg {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  fill: #000000;
}

@media only screen and (min-width: 768px) {
  .custom-map {
    margin-bottom: 100px;
  }
  .custom-map .map-wrapper {
    overflow: auto;
    max-width: 1300px;
    margin: auto;
  }
  .custom-map .map-wrapper .interactive-map {
    height: calc(100vh - 100px) !important;
    float: right;
    display: block;
  }
  .custom-map .map-wrapper .stores-wrapper {
    height: calc(100vh - 100px) !important;
    width: 40%;
    float: left;
  }
  .custom-map .map-wrapper .locations-list-wrapper {
    height: calc(100vh - 100px) !important;
  }
}

.gm-style {
  font: inherit !important;
}

.gm-style .gm-style-iw-c {
  border-radius: 0 !important;
}

.info-window-content .store-name {
  font-size: 16px;
  line-height: 1.2;
  margin-bottom: 5px;
  color: #000000;
}

.info-window-content .store-address {
  font-size: 14px;
  line-height: 1.2;
  margin-bottom: 10px;
}

.info-window-content .usage-type {
  font-size: 16px;
  margin-bottom: 10px;
}

.info-window-content a {
  text-decoration: none;
}

.map-info-window .button {
  margin-top: 10px;
}

@font-face {
  font-family: "Averta Regular";
  src: url("../fonts/Averta-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Averta Bold";
  font-weight: bold;
  src: url("../fonts/Averta-Bold.otf") format("opentype");
}

@font-face {
  font-family: "SE";
  src: url("../fonts/SohneSchmal-Extrafett.otf") format("opentype");
}

@font-face {
  font-family: "Dinamit-SemiBold";
  src: url("../fonts/Dinamit_Semibold.otf") format("opentype");
}

@font-face {
  font-family: "Dinamit-Bold";
  src: url("../fonts/Dinamit_Bold.otf") format("opentype");
}

@-webkit-keyframes mymove {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes mymove {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.font-bold {
  font-weight: bold;
}

.Dinamit.Bold {
  font-family: "Dinamit-Bold";
  margin-right: 12px;
}

.dinamit-font,
.dinamit-font p {
  font-family: "Dinamit-SemiBold";
  font-size: 18px;
  letter-spacing: 0.09px;
}

.mr-10 {
  margin-right: 10px;
}

.yellow {
  color: #ffcf00;
}

.blue {
  color: #0077d6;
}

.red {
  color: #ce0e2d;
}

.rose {
  color: #f48793;
}

.violet {
  color: #b6b8dd;
}

body {
  margin: 0;
  padding: 0;
  -moz-osx-font-smoothing: grayscale;
  /* Firefox */
  -webkit-font-smoothing: antialiased;
  /* WebKit  */
  font-family: "Averta Regular";
  background: #0077d6;
}

#root {
  overflow: hidden;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-40px {
  margin-top: 40px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.mr-5 {
  margin-right: 10px !important;
}

.mr-10 {
  margin-right: 20px !important;
}

.mr-15 {
  margin-right: 30px !important;
}

.mr-20 {
  margin-right: 40px !important;
}

.ml-5 {
  margin-left: 10px !important;
}

.ml-10 {
  margin-left: 20px !important;
}

.ml-15 {
  margin-left: 30px !important;
}

.ml-20 {
  margin-left: 40px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.wrapper {
  max-width: 1200px;
  margin: 0 auto;
}

.wrapper.no-max-width {
  max-width: 100%;
}

.wrapper.footer-wrapper {
  max-width: 600px;
  margin: 0;
}

.yellow-color {
  color: #e0fc01;
}

.top-border {
  border-top: 3px solid #e0fc01;
}

.uppercase {
  text-transform: uppercase;
}

.center-align {
  text-align: center !important;
}

.right-align {
  text-align: right !important;
}

input,
textarea,
select {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
  border-radius: 0 !important;
}

input {
  -webkit-box-shadow: none;
  box-shadow: none;
}

img {
  display: block;
}

h1 {
  margin: 0;
  white-space: pre-wrap;
  font-family: "SE";
  font-size: 88px;
  letter-spacing: -0.44px;
  -moz-osx-font-smoothing: grayscale;
  /* Firefox */
  -webkit-font-smoothing: antialiased;
  /* WebKit  */
}

h2 {
  margin: 0;
  font-family: "SE";
  font-size: 27px;
  letter-spacing: 0.14px;
  text-transform: uppercase;
  -moz-osx-font-smoothing: grayscale;
  /* Firefox */
  -webkit-font-smoothing: antialiased;
  /* WebKit  */
}

h3 {
  margin: 0;
  font-family: "Averta Bold";
  font-size: 20px;
  line-height: 1.2;
}

h6 {
  margin: 0;
  font-family: "Averta Bold";
  font-size: 12px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 20px;
}

p {
  margin: 0;
  font-size: 16px;
}

img {
  display: block;
}

a {
  text-decoration: none;
  color: inherit;
}

.medium {
  font-size: 14px;
}

.text-center {
  text-align: center;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.flex-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.width-100 {
  width: 100%;
}

.button-width-style {
  width: 100%;
}

@media only screen and (min-width: 768px) {
  h1 {
    font-size: 48px;
  }
  h2 {
    font-size: 32px;
  }
  h6 {
    font-size: 18px;
  }
  p {
    font-size: 18px;
  }
}

.button {
  display: inline-block !important;
  -webkit-appearance: none !important;
  font-size: 16px !important;
  padding: 10px 15px !important;
  font-weight: 900 !important;
  color: black !important;
  background: #fee602 !important;
  border: none !important;
  outline: none !important;
  letter-spacing: 1px;
  text-decoration: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: all 100ms ease-in-out;
  transition: all 100ms ease-in-out;
  white-space: nowrap !important;
  border-right: 2px solid transparent !important;
  border-bottom: 2px solid transparent !important;
  border-radius: 6px !important;
  text-transform: unset !important;
  cursor: pointer;
  font-family: "Averta Bold" !important;
}

.button:hover {
  color: white !important;
  border-right: 2px solid #004b82 !important;
  border-bottom: 2px solid #004b82 !important;
  background: #0084b7 !important;
  cursor: pointer;
}

.button.outlined {
  color: white !important;
  border: 2px solid white !important;
  background: none !important;
}

.button.outlined:hover {
  background: #fee602 !important;
  color: black !important;
  border: 2px solid #fee602 !important;
  cursor: pointer;
}

#age-gate {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

#age-gate .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

#age-gate .age-gate-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 50px;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
  z-index: 3;
  max-height: 600px;
  -ms-flex-item-align: center;
      align-self: center;
  text-align: center;
}

#age-gate .age-gate-content .spanish-toggle {
  position: absolute;
  top: 0;
  right: 0;
}

#age-gate .age-gate-content .spanish-toggle span {
  color: white;
}

#age-gate .age-gate-content .logo {
  width: 100%;
  height: auto;
  max-width: 150px;
  margin: 0 auto;
}

#age-gate .age-gate-content .logo img {
  width: 100%;
}

#age-gate .age-gate-content h2 {
  color: inherit;
  margin: 40px auto;
}

#age-gate .age-gate-content a {
  font-weight: bold;
}

#age-gate .age-gate-content p {
  margin: 20px 0px;
  color: inherit;
}

#age-gate .age-gate-content form {
  margin-bottom: 20px;
  line-height: 1;
  text-align: center !important;
}

#age-gate .age-gate-content form .error-message {
  display: block;
  position: absolute;
  padding: 20px;
  top: 0;
  left: 0;
  width: 100%;
  background: red;
  color: white;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

#age-gate .age-gate-content .legal-text p {
  font-size: 15px;
}

#age-gate .age-gate-content .legal-text a {
  font-weight: 900;
}

@media only screen and (min-width: 768px) {
  #age-gate .age-gate-content {
    padding: 50px;
  }
}

header {
  padding: 25px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

header .wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

header .wrapper .logo-container {
  width: auto;
  height: 20px;
  display: inline-block;
  margin-right: auto;
}

header .wrapper .logo-container .logo {
  width: auto;
  height: 20px;
}

header .wrapper .nav-menu {
  width: 24px;
  height: 20px;
  display: block;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  cursor: pointer;
}

header .wrapper .nav-menu .open-mobile-nav {
  width: 40px;
  height: 40px;
}

header .wrapper #navigation-container {
  position: relative;
  -webkit-transition: -webkit-transform 200ms ease-out;
  transition: -webkit-transform 200ms ease-out;
  transition: transform 200ms ease-out;
  transition: transform 200ms ease-out, -webkit-transform 200ms ease-out;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  text-align: left;
  position: fixed;
  top: 0;
  left: 0;
  background: #0077d6;
  height: 100vh;
  padding: 60px 0px;
  min-width: 60%;
  padding-top: 100px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  z-index: 100;
  -webkit-box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
          box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.5);
}

header .wrapper #navigation-container .open-mobile-nav {
  position: absolute;
  top: 28px;
  left: 17px;
}

header .wrapper #navigation-container .open-mobile-nav img {
  width: 40px;
  height: 40px;
}

header .wrapper #navigation-container.open {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  background: #0077d6;
}

header .wrapper #navigation-container.open a {
  color: #e0fc01;
}

header .wrapper #navigation-container a {
  display: block;
  color: #e0fc01;
  font-family: "Averta Bold";
  font-size: 22px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: normal;
  cursor: pointer;
  padding: 20px;
  border-top: 1px solid #02b5d5;
}

header .wrapper #navigation-container a:hover {
  text-decoration: underline;
  color: #02b5d5;
}

header .wrapper #navigation-container a.active {
  color: #e0fc01;
  text-decoration: underline;
}

header .wrapper #navigation-container a:first-of-type {
  padding: 0px 20px;
  border: 0;
}

header .wrapper #navigation-container a:last-of-type {
  border-bottom: 1px solid #02b5d5;
  margin-right: 0;
}

header .wrapper #navigation-container a .dropdown {
  display: none;
}

header .wrapper #navigation-container a .dropdown.dd-active {
  display: block;
}

header .wrapper #navigation-container a .dropdown a {
  padding-left: 0;
}

header .wrapper #navigation-container a .dropdown a:first-of-type {
  padding: 20px 0px;
  margin-top: 10px;
}

header .wrapper #navigation-container a .dropdown a:last-of-type {
  border-bottom: 0;
  padding-bottom: 0;
}

header .wrapper #navigation-container a img {
  width: 50%;
  margin-bottom: 30px;
}

header .wrapper #navigation-container .nav-close {
  width: 20px;
  height: 20px;
  background: black;
  display: block;
  position: absolute;
  left: 20px;
  top: 20px;
}

@media only screen and (min-width: 768px) {
  header .wrapper .nav-menu {
    display: none;
  }
  header .wrapper .logo-container {
    display: block;
    height: 30px;
    float: left;
  }
  header .wrapper .logo-container .logo {
    height: 30px;
    width: auto;
  }
  header .wrapper #navigation-container {
    -webkit-transform: translateX(0) !important;
            transform: translateX(0) !important;
    visibility: visible !important;
    transition: none !important;
    -webkit-box-shadow: none;
            box-shadow: none;
    position: static;
    background: none;
    width: auto;
    height: auto;
    padding: 0;
    transition: none;
    -webkit-transition: none;
    min-width: auto;
    float: right;
  }
  header .wrapper #navigation-container a {
    margin-bottom: 0;
    display: inline-block;
    margin-right: 20px;
    font-size: 16px;
    font-weight: 900;
    -webkit-transition: all 100ms ease-in-out;
    transition: all 100ms ease-in-out;
    color: #e0fc01;
    border: none !important;
    padding: 0;
    line-height: 30px;
  }
  header .wrapper #navigation-container a.active {
    color: #e0fc01;
    text-decoration: underline;
  }
  header .wrapper #navigation-container a .dropdown {
    display: none;
    position: absolute;
    top: 40px;
    right: 0;
    text-align: right;
    background: rgba(0, 0, 0, 0.25);
    z-index: 20;
    padding: 10px;
  }
  header .wrapper #navigation-container a .dropdown.dd-active {
    display: block;
  }
  header .wrapper #navigation-container a .dropdown a {
    display: block;
    padding: 0px 20px !important;
    text-align: right;
  }
  header .wrapper #navigation-container a .dropdown a:first-of-type {
    padding-top: 0;
    margin: 0 !important;
    border-top: none;
  }
}

.extra-margin {
  height: 70px;
}

@media only screen and (min-width: 600px) {
  .extra-margin {
    height: 80px;
  }
}

.hero-section {
  position: relative;
}

.hero-section .background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-size: contain !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
  -webkit-transition: -webkit-transform 500ms ease-in-out;
  transition: -webkit-transform 500ms ease-in-out;
  transition: transform 500ms ease-in-out;
  transition: transform 500ms ease-in-out, -webkit-transform 500ms ease-in-out;
}

.hero-section .background-color {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.hero-section .wrapper {
  min-height: 70vh;
}

.hero-section .wrapper .text-content {
  z-index: 3;
  position: relative;
  padding: 50px 25px;
  min-height: 70vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.hero-section .wrapper .text-content h1,
.hero-section .wrapper .text-content h2,
.hero-section .wrapper .text-content h3,
.hero-section .wrapper .text-content h4,
.hero-section .wrapper .text-content h5,
.hero-section .wrapper .text-content h6 {
  color: white;
}

.hero-section .wrapper .text-content p {
  color: white;
}

.hero-section .wrapper .text-content p img {
  max-width: 80%;
  margin: 0 auto;
}

.hero-section .wrapper .text-content .buttons .button {
  margin: 10px;
}

@media only screen and (max-width: 599px) {
  .hero-section .wrapper .text-content {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    text-align: center !important;
    margin-top: 50px;
  }
  .hero-section .wrapper .text-content .right-align {
    text-align: center !important;
  }
}

@media only screen and (min-width: 600px) {
  .hero-section.left-aligned .wrapper {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .hero-section.right-aligned .wrapper {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
  .hero-section .overlay {
    display: none;
  }
  .hero-section .wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .hero-section .wrapper .text-content {
    padding: 170px 50px;
    width: auto;
    width: 50%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    min-height: auto;
  }
  .hero-section .wrapper .text-content .buttons {
    margin-left: 0;
    margin-top: 20px;
  }
  .hero-section.vertical .wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .hero-section.vertical .wrapper .text-content {
    padding: 50px;
    width: 100%;
  }
}

@media only screen and (min-width: 768px) {
  .hide-desktop {
    display: none !important;
  }
  .section-half {
    padding: 100px 50px;
  }
  .section-half .wrapper {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .section-half .wrapper .text-content,
  .section-half .wrapper .img-content {
    width: 50%;
  }
  .section-half .wrapper .text-content {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
    margin-bottom: 0;
    margin-left: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding-right: 40px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
  }
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#landing-page {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-position: center center;
  position: relative;
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}

#landing-page #logo {
  position: absolute;
  bottom: 10px;
  left: 10px;
  width: 40%;
  height: auto;
}

#landing-page #logo img {
  width: 100%;
  height: auto;
  display: block;
}

@media only screen and (min-width: 768px) {
  #landing-page #logo {
    width: 20%;
  }
}

.products {
  background: black;
  padding: 50px 25px;
  text-align: center;
}

.products .section-description {
  color: white;
  text-align: center;
  margin-bottom: 20px;
}

.products .button {
  margin-top: 20px;
}

.products .product-icons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.products .product-icons .product-icon {
  text-align: center;
  width: 30%;
}

.products .product-icons .product-icon img {
  width: 100px;
  margin: 0 auto;
  margin-bottom: 20px;
}

.products .product-icons .product-icon h3 {
  color: white;
  text-transform: uppercase;
  font-size: 12px;
}

@media only screen and (min-width: 768px) {
  .products {
    padding: 100px 50px;
  }
  .products .product-icons .product-icon {
    width: auto;
  }
  .products .product-icons .product-icon img {
    width: 150px;
  }
}

.logo-text {
  font-weight: 900;
  font-size: 16px;
  letter-spacing: 8px;
  white-space: nowrap;
}

footer {
  background: #0077d6;
  background-size: cover;
  background-position: center center;
  color: #e0fc01;
}

footer .wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

footer .wrapper .footer-section {
  margin-bottom: 30px;
  width: 50%;
}

footer .wrapper .footer-section h2 {
  font-size: 16px;
  font-family: "Averta Bold";
}

footer .wrapper .footer-section p {
  font-size: 14px;
  margin-bottom: 10px;
}

footer .wrapper .footer-section p:last-of-type {
  margin-bottom: 0;
}

footer .wrapper .footer-section p.copyright {
  margin-bottom: 20px;
  font-weight: 900;
}

footer .wrapper .footer-section .social {
  margin-top: 20px;
}

footer .wrapper .footer-section .social a {
  display: inline-block;
  margin-right: 10px;
  width: 20px;
  height: auto;
  margin-bottom: 0;
  color: #e0fc01;
}

footer .wrapper .footer-section .social a img {
  width: 100%;
  height: auto;
}

footer .wrapper .footer-section a {
  display: block;
  color: #e0fc01;
  margin-bottom: 10px;
}

footer .wrapper .footer-section a:last-of-type {
  margin-bottom: 0;
}

footer .wrapper .footer-section:first-of-type, footer .wrapper .footer-section:last-of-type {
  width: 100%;
}

footer .wrapper .footer-section:last-of-type {
  margin-bottom: 0;
}

@media only screen and (min-width: 900px) {
  footer {
    padding: 60px 0 60px 60px !important;
  }
  footer .wrapper {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  footer .wrapper .footer-section {
    margin-right: 20px;
    width: auto;
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
  }
  footer .wrapper .footer-section .social {
    margin: 0;
  }
  footer .wrapper .footer-section .copyright {
    margin-top: 0;
  }
  footer .wrapper .footer-section:last-of-type {
    -ms-flex-preferred-size: 100%;
        flex-basis: 100%;
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3;
  }
}

.instagram-section {
  padding: 56px 24px;
}

.instagram-section .photos {
  margin-top: 25px;
}

.instagram-section .photos img {
  display: none;
  width: 100%;
}

.instagram-section .photos img:first-of-type {
  display: block;
}

@media only screen and (min-width: 600px) {
  .instagram-section {
    padding: 100px 50px;
  }
  .instagram-section h2 {
    float: left;
  }
  .instagram-section .button {
    float: right;
    margin-bottom: 25px;
  }
  .instagram-section .photos {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    clear: both;
  }
  .instagram-section .photos img {
    display: block;
    width: calc(100% / 3 - 20px);
    margin-right: 30px;
  }
  .instagram-section .photos img:last-of-type {
    margin-right: 0;
  }
}

.products-page-section {
  min-height: 400px;
}

.products-page-section .wrapper .carousel {
  padding: 25px;
}

.products-page-section .wrapper .carousel .options span {
  display: inline-block;
  padding: 10px;
  font-size: 12px;
  font-weight: 900;
  text-transform: uppercase;
  border-radius: 16px;
  -webkit-box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.12);
          box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.12);
  margin-right: 10px;
  margin-bottom: 10px;
  border: 2px solid transparent;
  cursor: pointer;
}

.products-page-section .wrapper .carousel .options span:last-of-type {
  margin-right: 0;
}

.products-page-section .wrapper .carousel .options span.active {
  background: black;
  color: white;
}

.products-page-section .wrapper .carousel .form-info-container .selected-form {
  width: 60%;
  height: auto;
  margin: 25px auto;
}

.products-page-section .wrapper .carousel .form-info-container .text-content p:last-of-type {
  margin-top: 15px;
}

.products-page-section .wrapper .product-carousel {
  position: relative;
}

.products-page-section .wrapper .product-carousel .images {
  width: 100%;
  height: 50vh;
}

.products-page-section .wrapper .product-carousel .images .product-image {
  width: 100%;
  height: 100%;
  display: none;
  -o-object-fit: cover !important;
     object-fit: cover !important;
  -o-object-position: center center !important;
     object-position: center center !important;
}

.products-page-section .wrapper .product-carousel .images .product-image.active {
  display: block;
}

.products-page-section .wrapper .product-carousel .controls {
  position: absolute;
  bottom: 25px;
  right: 25px;
  z-index: 2;
}

.products-page-section .wrapper .product-carousel .controls div {
  background: white;
  border-radius: 100%;
  float: left;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
}

.products-page-section .wrapper .product-carousel .controls .forward:before {
  content: ">";
}

.products-page-section .wrapper .product-carousel .controls .back {
  margin-right: 20px;
}

.products-page-section .wrapper .product-carousel .controls .back:before {
  content: "<";
}

@media only screen and (min-width: 600px) {
  .products-page-section .wrapper .carousel {
    padding: 25px 50px;
  }
  .products-page-section .wrapper .carousel .options {
    text-align: center;
  }
  .products-page-section .wrapper .carousel .form-info-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .products-page-section .wrapper .carousel .form-info-container .selected-form {
    margin-right: 25px;
  }
  .products-page-section .wrapper .carousel .form-info-container .text-content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    padding: 25px 0px;
  }
}

@media only screen and (min-width: 900px) {
  .products-page-section {
    padding: 50px;
  }
  .products-page-section .wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
  }
  .products-page-section .wrapper .product-carousel {
    min-width: 60%;
    margin: 0;
  }
  .products-page-section .wrapper .product-carousel .images {
    height: 100%;
  }
  .products-page-section .wrapper .carousel {
    padding: 0;
    padding-right: 25px;
    min-width: 25%;
  }
  .products-page-section .wrapper .carousel .options {
    text-align: left;
  }
  .products-page-section .wrapper .carousel .form-info-container {
    display: block;
  }
  .products-page-section .wrapper .carousel .form-info-container .selected-form {
    margin: 25px auto;
    width: 100%;
  }
  .products-page-section .wrapper .carousel .form-info-container .text-content {
    padding: 0;
  }
}

.simple-section {
  padding: 50px 25px;
}

@media only screen and (min-width: 600px) {
  .simple-section {
    padding: 100px 50px;
  }
  .simple-section .wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .simple-section .wrapper h2 {
    margin-right: 10%;
  }
}

.simple-page {
  padding: 25px;
}

.simple-page p,
.simple-page li,
.simple-page a {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  color: black;
  letter-spacing: normal;
}

.simple-page p:last-of-type,
.simple-page li:last-of-type,
.simple-page a:last-of-type {
  margin-bottom: 0;
}

.simple-page.full-screen-page {
  padding: 0 !important;
  margin-top: 74px;
}

@media only screen and (min-width: 600px) {
  .simple-page {
    padding: 50px;
  }
  .simple-page.full-screen-page {
    padding: 0 !important;
    margin-top: 74px;
  }
}

@media only screen and (min-width: 768px) {
  .simple-page.full-screen-page {
    margin-top: 86px;
  }
}

.map-wrapper {
  overflow: auto;
  background-size: cover;
}

.map-wrapper .interactive-map {
  width: 100%;
  display: block;
  position: relative !important;
  display: none;
}

.map-wrapper .locations-list-wrapper {
  display: block;
  padding-top: 74px;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.map-wrapper .locations-list-wrapper.custom-map {
  padding-top: 0;
}

.map-wrapper .locations-list-wrapper.custom-map .locations-list-title {
  margin-top: 0;
}

.map-wrapper .locations-list-wrapper .locations-list-title {
  margin: 0;
  padding: 0px 20px;
  color: white;
  margin-top: 20px;
}

.map-wrapper .locations-list-wrapper .pagination-nav {
  padding: 8px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  background: #e0fc01;
  color: black;
}

.map-wrapper .locations-list-wrapper .pagination-nav .page-controls span {
  cursor: pointer;
}

.map-wrapper .locations-list-wrapper .pagination-nav .page-controls span:nth-of-type(1) {
  margin-right: 10px;
}

.map-wrapper .locations-list-wrapper .info-message {
  background: #0069b5;
  color: white;
  padding: 8px 20px;
}

.map-wrapper .locations-list-wrapper .locations-search-form {
  padding: 10px 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.map-wrapper .locations-list-wrapper .locations-list {
  background: white;
  overflow: scroll;
  height: 100%;
  position: relative;
}

.map-wrapper .locations-list-wrapper .locations-list .single-location {
  padding: 20px;
  overflow: auto;
  border-bottom: 1px solid #000000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.map-wrapper .locations-list-wrapper .locations-list .single-location:last-of-type {
  border-bottom: 0;
}

.map-wrapper .locations-list-wrapper .locations-list .single-location.active {
  background: #eaf9ff;
}

.map-wrapper .locations-list-wrapper .locations-list .single-location .store-information {
  margin-right: 10px;
  padding-right: 10px;
  border-right: 1px solid #000000;
  width: 100%;
  color: #000000;
}

.map-wrapper .locations-list-wrapper .locations-list .single-location .store-information .store-name {
  font-size: 14px;
  line-height: 1.2;
  margin-bottom: 5px;
  color: #0069b5;
  font-family: "Dinamit-SemiBold";
}

.map-wrapper .locations-list-wrapper .locations-list .single-location .store-information .store-address {
  font-size: 14px;
  line-height: 1.2;
  margin-bottom: 10px;
}

.map-wrapper .locations-list-wrapper .locations-list .single-location .store-information .distance {
  font-size: 14px;
  line-height: 1.2;
}

.map-wrapper .locations-list-wrapper .locations-list .single-location .store-information .usage-type {
  font-size: 12px;
  font-weight: 900;
  margin-bottom: 0;
}

.map-wrapper .locations-list-wrapper .locations-list .single-location .store-actions {
  min-width: 110px;
}

.map-wrapper .locations-list-wrapper .locations-list .single-location .store-actions .icon-button {
  color: #000000;
  font-size: 10px;
  display: block;
  text-decoration: none;
  font-weight: 900;
  margin-bottom: 10px;
  white-space: nowrap;
}

.map-wrapper .locations-list-wrapper .locations-list .single-location .store-actions .icon-button svg {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
  fill: #000000;
}

@media only screen and (min-width: 768px) {
  .map-wrapper {
    margin-top: 80px;
    -webkit-box-shadow: inset -4px 8px 10px -10px #0b5d9c;
            box-shadow: inset -4px 8px 10px -10px #0b5d9c;
    overflow: auto;
  }
  .map-wrapper .interactive-map {
    height: 100vh !important;
    width: 60% !important;
    float: right;
    display: block;
  }
  .map-wrapper .stores-wrapper {
    height: calc(100vh - 73px) !important;
    width: 40%;
    float: left;
  }
}

.gm-style {
  font: inherit !important;
}

.gm-style .gm-style-iw-c {
  border-radius: 0 !important;
}

.info-window-content .store-name {
  font-size: 16px;
  line-height: 1.2;
  margin-bottom: 5px;
  color: #000000;
}

.info-window-content .store-address {
  font-size: 14px;
  line-height: 1.2;
  margin-bottom: 10px;
}

.info-window-content .usage-type {
  font-size: 16px;
  margin-bottom: 10px;
}

.info-window-content a {
  text-decoration: none;
}

#alert-dialog-description p a {
  color: black;
  text-decoration: underline;
}

.no-flick {
  min-height: 80vh;
}

.animation {
  opacity: 0;
}

.animation.yas {
  -webkit-transition: opacity 300ms ease-in-out;
  transition: opacity 300ms ease-in-out;
  opacity: 1;
}

.animation.yas .hero-section .background-image {
  -webkit-transform: scale(1);
          transform: scale(1);
}

.map-info-window .button {
  margin-top: 10px;
}

.featured-section .wrapper .featured-item {
  margin: 0 auto;
  margin-bottom: 16px;
}

.featured-section .wrapper .featured-item:last-of-type {
  margin-bottom: 0;
}

.featured-section .wrapper .featured-item .image {
  border-radius: 10px;
  border: 3px solid white;
  overflow: hidden;
}

.featured-section .wrapper .featured-item .image img {
  width: 100%;
  height: auto;
  display: block;
}

.featured-section .wrapper .featured-item .content {
  padding: 20px;
}

@media only screen and (min-width: 600px) {
  .featured-section .wrapper {
    max-width: 900px;
  }
  .featured-section .wrapper .featured-item {
    margin-bottom: 0 !important;
    max-width: calc(50% - 25px);
  }
  .featured-section .wrapper .featured-item .image {
    margin: 0 auto;
  }
  .featured-section .wrapper .featured-item:first-of-type {
    margin-right: 25px;
  }
  .featured-section .wrapper .featured-item:last-of-type {
    margin-left: 25px;
  }
  .featured-section .wrapper .featured-item .content {
    padding: 20px 0px;
  }
}

@media only screen and (min-width: 1200px) {
  .featured-section .wrapper .featured-item {
    max-width: calc(50% - 24px);
  }
  .featured-section .wrapper .featured-item:first-of-type {
    margin-right: 24px;
  }
  .featured-section .wrapper .featured-item:last-of-type {
    margin-left: 24px;
  }
}

.text-section {
  background-size: cover !important;
  background-position: center center !important;
}

.text-section .wrapper p {
  margin: 20px 0px;
}

@media only screen and (min-width: 600px) {
  .text-section {
    padding: 114px 48px;
  }
}

@media only screen and (min-width: 768px) {
  .flex-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .flex-wrapper.lead-image .image-wrapper {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
  }
}

.product-section {
  background: #0077d6;
  color: white;
}

.product-section .wrapper .button-wrapper {
  margin: 24px 0px;
}

.product-section .wrapper .content-wrapper {
  max-width: 600px;
}

.product-section .wrapper .products-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  overflow: scroll;
}

.product-section .wrapper .products-wrapper .product {
  min-width: 130px;
}

.product-section .wrapper .products-wrapper .product a:hover img {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.product-section .wrapper .products-wrapper .product img {
  width: 100%;
  display: block;
  max-width: 250px;
  margin: 0 auto;
  -webkit-transition: all 100ms ease-in-out;
  transition: all 100ms ease-in-out;
}

.product-section .wrapper .products-wrapper .product .content {
  margin-top: 20px;
}

@media only screen and (min-width: 768px) {
  .product-section .wrapper .content-wrapper {
    margin-right: 30px;
    margin-bottom: 50px;
  }
  .product-section .wrapper .button-wrapper {
    margin: 0;
  }
  .product-section .wrapper .flex-wrapper {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .product-section .wrapper .products-wrapper {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    overflow: hidden;
  }
  .product-section .wrapper .products-wrapper .product {
    margin: 0px 20px;
  }
}

.card-swipe-section.standard-padding {
  padding-bottom: 35px;
}

.card-swipe-section .button-wrapper {
  margin: 24px 0px;
}

.card-swipe-section .products-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: scroll;
  padding-bottom: 15px;
}

.card-swipe-section .products-wrapper .card {
  min-width: 230px;
  min-height: 230px;
  margin-right: 16px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  cursor: pointer;
}

.card-swipe-section .products-wrapper .card:hover .content {
  opacity: 1;
}

.card-swipe-section .products-wrapper .card .image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: hidden;
}

.card-swipe-section .products-wrapper .card .image img {
  width: 100%;
  height: 100%;
  display: block;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center center;
     object-position: center center;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.card-swipe-section .products-wrapper .card .content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: white;
  padding: 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  text-align: center;
  opacity: 0;
  background: rgba(0, 0, 0, 0.25);
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.card-swipe-section .products-wrapper .card .content h2 {
  margin-bottom: 10px;
}

.card-swipe-section .products-wrapper .card .content h3 {
  margin-bottom: 0;
}

@media only screen and (min-width: 768px) {
  .card-swipe-section .wrapper .flex-wrapper .flex-child:nth-of-type(1) {
    margin-right: 48px;
    max-width: 50%;
  }
  .card-swipe-section .wrapper .flex-wrapper .flex-child:nth-of-type(2) {
    width: 100%;
    width: 50%;
  }
  .card-swipe-section .wrapper .flex-wrapper .flex-child .products-wrapper {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
    -ms-grid-rows: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
    -webkit-column-gap: 24px;
            column-gap: 24px;
    row-gap: 24px;
    padding: 0;
  }
  .card-swipe-section .wrapper .flex-wrapper .flex-child .products-wrapper.products-carousel {
    display: block;
  }
  .card-swipe-section .wrapper .flex-wrapper .flex-child .products-wrapper.products-carousel .control-arrow {
    width: 50px;
    height: 50px;
    top: calc(50% - 25px);
    border-radius: 100%;
  }
  .card-swipe-section .wrapper .flex-wrapper .flex-child .products-wrapper.products-carousel .control-arrow.control-prev {
    left: 10px;
  }
  .card-swipe-section .wrapper .flex-wrapper .flex-child .products-wrapper.products-carousel .control-arrow.control-next {
    right: 10px;
  }
  .card-swipe-section .wrapper .flex-wrapper .flex-child .products-wrapper .card {
    margin: 0;
    min-width: auto;
    min-height: auto;
  }
  .card-swipe-section .wrapper .flex-wrapper .flex-child .products-wrapper .card .image {
    position: static;
  }
}

.text-image-section .wrapper {
  max-width: 1300px;
}

.text-image-section .wrapper .content-wrapper {
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.text-image-section .wrapper .button-wrapper {
  margin-top: 24px;
}

.text-image-section .wrapper .image-wrapper {
  max-width: 50%;
  margin-left: auto;
  margin-top: -100px;
}

.text-image-section .wrapper .image-wrapper img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center center;
     object-position: center center;
}

.text-image-section .wrapper.full-width {
  max-width: none !important;
}

.text-image-section .wrapper.full-width .image-wrapper {
  margin-left: 0;
  margin-top: 0;
  max-width: none;
}

@media only screen and (min-width: 768px) {
  .text-image-section .wrapper .image-wrapper {
    min-width: 50%;
    max-width: 50%;
    -ms-flex-item-align: stretch;
        -ms-grid-row-align: stretch;
        align-self: stretch;
    margin-left: 0;
    margin-top: 0;
    max-width: none;
  }
}

@media only screen and (min-width: 768px) {
  .button-wrapper .button {
    width: auto !important;
  }
}

.forms-section {
  -webkit-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
  overflow: hidden;
}

.forms-section .wrapper .flex-wrapper {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.forms-section .wrapper .flex-wrapper .content-wrapper {
  margin-bottom: 15px;
  z-index: 1;
}

.forms-section .wrapper .flex-wrapper .content-wrapper .text-content {
  margin-bottom: 20px;
}

.forms-section .wrapper .flex-wrapper .content-wrapper .pills {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: scroll;
  padding-bottom: 15px;
}

.forms-section .wrapper .flex-wrapper .content-wrapper .pills .button {
  margin-right: 8px;
  cursor: pointer;
}

.forms-section .wrapper .flex-wrapper .content-wrapper .pills .button.active {
  background: #fee602 !important;
  color: black !important;
  border: 2px solid #fee602 !important;
}

.forms-section .wrapper .flex-wrapper .image-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.forms-section .wrapper .flex-wrapper .image-wrapper .content {
  margin-top: 30px;
}

.forms-section .wrapper .flex-wrapper .image-wrapper .form-images {
  position: relative;
  height: 50vh;
  z-index: 0;
  -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
          order: -1;
}

.forms-section .wrapper .flex-wrapper .image-wrapper .form-images .form-image {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  bottom: 0;
  left: 0;
  -webkit-transform: translateX(50%);
          transform: translateX(50%);
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.forms-section .wrapper .flex-wrapper .image-wrapper .form-images .form-image.visible {
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0);
}

.forms-section .wrapper .flex-wrapper .image-wrapper .form-images .form-image img {
  height: 100%;
  width: 100%;
  max-width: 400px;
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center center;
     object-position: center center;
  -webkit-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}

.forms-section .wrapper .form-content {
  display: none;
}

.forms-section .wrapper .form-content.visible {
  display: block;
}

@media only screen and (min-width: 768px) {
  .forms-section .wrapper .flex-wrapper {
    min-height: 70vh;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: unset;
        -ms-flex-pack: unset;
            justify-content: unset;
  }
  .forms-section .wrapper .flex-wrapper .content-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .forms-section .wrapper .flex-wrapper .content-wrapper .text-content {
    padding-right: 20px;
    width: auto;
  }
  .forms-section .wrapper .flex-wrapper .content-wrapper .text-content h2 {
    margin: 0;
  }
  .forms-section .wrapper .flex-wrapper .content-wrapper .pills {
    display: block;
    overflow: unset;
    width: auto;
    padding-bottom: 0;
    text-align: right;
  }
  .forms-section .wrapper .flex-wrapper .content-wrapper .pills .button {
    margin-bottom: 8px;
  }
  .forms-section .wrapper .flex-wrapper .image-wrapper {
    min-width: 50%;
    height: auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .forms-section .wrapper .flex-wrapper .image-wrapper .content {
    width: 50%;
    margin-top: 0;
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
  }
  .forms-section .wrapper .flex-wrapper .image-wrapper .form-images {
    width: 50%;
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
  }
  .forms-section .wrapper .flex-wrapper .image-wrapper .form-images .form-image {
    padding: 0;
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
  }
  .forms-section .wrapper .flex-wrapper .image-wrapper .form-images .form-image.visible {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  .forms-section .wrapper .flex-wrapper .image-wrapper .form-images .form-image img {
    max-width: none;
    -o-object-position: center right;
       object-position: center right;
  }
  .forms-section .wrapper .flex-wrapper .image-wrapper .form-images.lead-image {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
  }
  .forms-section .wrapper .flex-wrapper .image-wrapper .form-images.lead-image img {
    -o-object-position: center left;
       object-position: center left;
  }
}

.moods-section .wrapper .flex-wrapper .mood-cards-wrapper {
  margin-top: 24px;
}

.moods-section .wrapper .flex-wrapper .mood-cards-wrapper .mood-card {
  height: 100%;
  -webkit-box-shadow: 1px -1px 4px 0 rgba(0, 0, 0, 0.1);
          box-shadow: 1px -1px 4px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  display: none;
}

.moods-section .wrapper .flex-wrapper .mood-cards-wrapper .mood-card img {
  width: 100%;
  height: auto;
}

.moods-section .wrapper .flex-wrapper .mood-cards-wrapper .mood-card .content {
  padding: 32px 24px;
}

.moods-section .wrapper .flex-wrapper .mood-cards-wrapper .mood-card.visible {
  display: block;
}

.moods-section .wrapper .flex-wrapper .mood-images-wrapper {
  margin-top: 16px;
}

.moods-section .wrapper .flex-wrapper .mood-images-wrapper img {
  width: 100%;
}

.moods-section .wrapper .flex-wrapper .mood-images-wrapper .mood-image {
  display: none;
}

.moods-section .wrapper .flex-wrapper .mood-images-wrapper .mood-image.visible {
  display: block;
}

@media only screen and (min-width: 768px) {
  .moods-section .wrapper .flex-wrapper {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .moods-section .wrapper .flex-wrapper .mood-cards-wrapper,
  .moods-section .wrapper .flex-wrapper .mood-images-wrapper {
    margin-top: 0;
  }
  .moods-section .wrapper .flex-wrapper .mood-cards-wrapper {
    width: calc(50% - 32px);
    margin-right: 32px;
    -ms-flex-item-align: stretch;
        -ms-grid-row-align: stretch;
        align-self: stretch;
  }
}

.standard-padding {
  padding: 50px 25px;
}

.standard-padding-less {
  padding: 25px 15px;
}

@media only screen and (min-width: 600px) {
  .standard-padding {
    padding: 50px;
  }
}

.faq-section table h2,
.faq-section table h3,
.faq-section table h4,
.faq-section table h5,
.faq-section table h6 {
  margin: 0 !important;
}

.faq-section table div {
  margin: 0;
}

.faq-section table .answer {
  padding: 20px;
}

.website-message {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background: #00286f;
  z-index: 1000;
}

.website-message .button {
  margin-left: 20px;
}

.pdf-section .react-pdf__Document {
  pointer-events: none;
  -webkit-user-select: none;
  overflow: hidden;
}

.pdf-section .react-pdf__Page__canvas {
  width: 100% !important;
  height: auto !important;
}

.pdf-section .pdf-navigation {
  text-align: center;
  z-index: 20;
  position: relative;
}

.pdf-section .pdf-navigation .page-indicator {
  margin-bottom: 10px;
}

.pdf-section .pdf-navigation .button:first-of-type {
  margin-right: 10px;
}

.pdf-section .pdf-navigation .button {
  z-index: 20;
}

.iframe-slice {
  width: 100%;
  background: white;
  padding: 60px 0px;
}

.iframe-slice iframe {
  width: 100%;
  height: 70vh;
  border: 0;
}

#contact-page .wrapper {
  max-width: 700px;
  padding: 20px;
}

#contact-header {
  background: #0077d6;
  margin-top: 75px;
}

#contact-header h1 {
  text-align: center;
  padding: 40px 0px;
  color: white;
  margin: 0;
}

.form-message {
  display: block;
  color: black;
  background: rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.form-message.error {
  margin-bottom: 2rem;
  color: white;
  background: none;
  background: #ad0000;
  font-weight: bold;
}

.form-message .underline {
  text-decoration: underline;
  font-weight: bold;
}

.form-message.success .brandColor {
  color: #fee602;
  font-weight: bold;
}

.MuiFormLabel-root.Mui-error {
  background: #ad0000 !important;
  color: white !important;
  display: inline-block !important;
  padding: 8px;
  border-radius: 5px;
  font-weight: bold;
}

.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #ad0000 !important;
}

.tooltipButton {
  margin: 0;
  padding: 0;
  height: 18px;
  width: 18px;
  background: none;
  border: none;
  cursor: pointer;
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.accessibility {
  display: inline-block;
  margin-left: 10px;
  position: relative;
  bottom: 6px;
}

.yellow-font {
  color: #e0fc01;
}

.navbar.scrolled {
  background: #0077d6;
  -webkit-box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
          box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
}

@media only screen and (min-width: 600px) {
  .new-hero-copy h1 {
    font-size: 150px;
    line-height: 120px !important;
  }
  .new-hero-copy h1 .yellow-color {
    bottom: 5px !important;
  }
}

@media only screen and (min-width: 810px) {
  .new-hero-copy h1 {
    font-size: 200px;
    line-height: 155px !important;
  }
  .new-hero-copy h1 .yellow-color {
    bottom: 10px !important;
  }
}

@media only screen and (min-width: 1120px) {
  .new-hero-copy h1 {
    font-size: 250px;
    line-height: 191px !important;
  }
  .new-hero-copy h1 .yellow-color {
    bottom: 10px !important;
  }
}

@media only screen and (min-width: 1310px) {
  .new-hero-copy h1 .yellow-color {
    bottom: 5px !important;
  }
}

@media only screen and (min-width: 1386px) {
  .new-hero-copy h1 {
    font-size: 280px;
    line-height: 220px !important;
  }
  .new-hero-copy h1 .yellow-color {
    bottom: 20px !important;
  }
}

@media only screen and (min-width: 1850px) {
  .new-hero-copy h1 {
    font-size: 300px;
    line-height: 240px !important;
  }
  .new-hero-copy h1 .yellow-color {
    bottom: 25px !important;
  }
}

@media only screen and (min-width: 1400px) {
  .standard-padding-less {
    padding: 25px 70px;
  }
  footer {
    padding: 109px 0 109px 131px !important;
  }
  header .wrapper {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  header .wrapper .logo-container {
    margin-right: 670px;
  }
  header .wrapper .logo-container .logo {
    height: 40px;
  }
  header .wrapper #navigation-container a {
    margin-right: 60px !important;
    font-size: 20px;
  }
}

.deep-yellow-background {
  background: #fdc402;
}

.orange-background {
  color: #fdc402;
  background: #fe5e00;
}

.inheritHW {
  height: inherit;
  width: inherit;
}

.orange-font {
  color: #fe5e00;
}

.orange-font h2 {
  line-height: 0.9;
}

.orange-font p {
  font-size: 14px;
  font-weight: bold;
}

@media only screen and (min-width: 850px) {
  .orange-font p {
    font-size: 16px;
  }
}

.big-game-btns {
  margin-top: 20px;
}

.big-game-btns button {
  background: #fe5e00 !important;
  color: #fdc402 !important;
  font-family: "SE";
  font-size: 18px;
  padding: 5px 25px;
  white-space: nowrap;
  min-width: 155px;
  margin-bottom: 10px;
  margin-right: 20px;
}

@media only screen and (min-width: 1300px) {
  .big-game-btns button {
    font-size: 28px;
    text-align: center;
    min-width: 200px;
  }
}

@media only screen and (min-width: 1500px) {
  .big-game-btns button {
    font-size: 35px;
    min-width: 230px;
  }
}

@media only screen and (min-width: 1300px) {
  .big-game-btns {
    margin-top: 50px;
    text-align: center;
  }
}

.big-game-locations {
  padding: 40px 20px;
  text-align: center;
}

.big-game-locations .copy h2 {
  margin-bottom: 10px;
}

.big-game-locations .copy p {
  max-width: 850px;
  margin: auto;
  line-height: 1.3;
}

.big-game-locations .locations {
  margin-top: 30px;
}

.big-game-locations .locations h3 {
  font-family: "SE";
  font-size: 25px;
}

.big-game-locations .locations .location-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 20px;
  gap: 3px;
}

.big-game-locations .locations .location-info p {
  font-size: 14px;
}

@media only screen and (min-width: 400px) {
  .big-game-locations .locations .location-info p {
    font-size: 15px;
  }
}

.big-game-locations .locations .location-info .state p:after {
  content: ",";
}

@media only screen and (min-width: 850px) {
  .big-game-locations {
    padding: 40px 20px 50px;
  }
  .big-game-locations .copy h2 {
    font-size: 42px;
    margin-bottom: 10px;
  }
  .big-game-locations .locations {
    margin-top: 30px;
  }
  .big-game-locations .locations h3 {
    font-size: 35px;
  }
  .big-game-locations .locations .location {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .big-game-locations .locations .location:hover {
    text-decoration: underline;
  }
  .big-game-locations .locations .location .location-name {
    margin-right: 5px;
  }
  .big-game-locations .locations .location-info p {
    font-size: 18px;
  }
}

@media only screen and (min-width: 1300px) {
  .big-game-locations {
    padding: 60px 20px 90px;
  }
  .big-game-locations .copy h2 {
    font-size: 90px;
    margin-bottom: 10px;
    text-align: center;
  }
  .big-game-locations .copy p {
    font-size: 24px;
    text-align: center;
    line-height: 1.3;
    max-width: 825px;
    margin: auto;
  }
  .big-game-locations .locations {
    margin-top: 50px;
  }
  .big-game-locations .locations p,
  .big-game-locations .locations .location-info p {
    font-size: 18px;
  }
}

@media only screen and (min-width: 1540px) {
  .locations-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-top: 40px;
    gap: 70px;
  }
}

@media only screen and (min-width: 1540px) and (min-width: 1630px) {
  .locations-container {
    gap: 100px;
  }
}

@media only screen and (min-width: 1540px) and (min-width: 1700px) {
  .locations-container {
    gap: 150px;
  }
}
