.custom-map {
  .map-wrapper {
    overflow: auto;
    background-size: cover;
    .interactive-map {
      width: 100%;
      display: block;
      position: relative !important;
      display: none;
    }
    .locations-list-wrapper {
      display: block;
      height: 100vh;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
      padding-top: 0;

      .locations-list-title {
        margin: 0;
        padding: 20px 20px 0;
        color: white;
        margin-top: 20px;
      }
      .pagination-nav {
        padding: 8px 20px;
        display: flex;
        justify-content: space-between;
        background: $new-yellow;
        color: black;
        .page-controls {
          span {
            cursor: pointer;
            &:nth-of-type(1) {
              margin-right: 10px;
            }
          }
        }
      }
      .info-message {
        background: $new-darkBlue;
        color: white;
        padding: 8px 20px;
        border-top: 1px solid #00497e;
      }
      .locations-search-form {
        padding: 10px 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      .locations-search-form-wrapper {
        background: $new-darkBlue;
      }
      .locations-list {
        background: white;
        overflow: scroll;
        height: 100%;
        position: relative;
        .single-location {
          padding: 20px;
          overflow: auto;
          border-bottom: 1px solid #000000;
          display: flex;
          flex-direction: row;
          &:last-of-type {
            border-bottom: 0;
          }
          &.active {
            background: lighten($new-lightBlue, 50%);
          }

          .store-information {
            margin-right: 10px;
            padding-right: 10px;
            border-right: 1px solid #000000;
            width: 100%;
            color: #000000;
            .store-name {
              font-size: 16px;
              line-height: 1.2;
              margin-bottom: 5px;
              color: $new-darkBlue;
            }
            .store-address {
              font-size: 14px;
              line-height: 1.2;
              margin-bottom: 10px;
            }
            .distance {
              font-size: 14px;
              line-height: 1.2;
            }
            .usage-type {
              font-size: 12px;
              font-weight: 900;
              margin-bottom: 0;
            }
          }
          .store-actions {
            min-width: 110px;
            .icon-button {
              color: #000000;
              font-size: 10px;
              display: block;
              text-decoration: none;
              font-weight: 900;
              margin-bottom: 10px;
              white-space: nowrap;
              svg {
                display: inline-block;
                vertical-align: middle;
                margin-right: 5px;
                fill: #000000;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .custom-map {
    margin-bottom: 100px;
    .map-wrapper {
      overflow: auto;
      max-width: 1300px;
      margin: auto;
      .interactive-map {
        height: calc(100vh - 100px) !important;

        float: right;
        display: block;
      }
      .stores-wrapper {
        height: calc(100vh - 100px) !important;
        width: 40%;
        float: left;
      }
      .locations-list-wrapper {
        height: calc(100vh - 100px) !important;
      }
    }
  }
}

.gm-style {
  font: inherit !important;
  .gm-style-iw-c {
    border-radius: 0 !important;
  }
}

.info-window-content {
  .store-name {
    font-size: 16px;
    line-height: 1.2;
    margin-bottom: 5px;
    color: #000000;
  }
  .store-address {
    font-size: 14px;
    line-height: 1.2;
    margin-bottom: 10px;
  }
  .usage-type {
    font-size: 16px;
    margin-bottom: 10px;
  }
  a {
    text-decoration: none;
  }
}

.map-info-window {
  .button {
    margin-top: 10px;
  }
}
